import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tokenNotExpired } from 'angular2-jwt'
import { CallApiService } from '../../configs/call-api.service';
import { Url } from './../../configs/url';
import * as io from 'socket.io-client';
import { Const } from './../../configs/constant';
import {AppSettings} from './../../app-setting/app-setting'
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public authToken: string;
    public language = localStorage.getItem('language') ? localStorage.getItem('language') : 'vi';
    public socket = io(AppSettings.API_ROOT, {
        transports: ['polling'],
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity
    });

    constructor(
        private callApiService: CallApiService
    ) {
        //   this.socket = io(Const.API_ROOT, {
        //     reconnection: true,
        //     reconnectionDelay: 1000,
        //     reconnectionDelayMax: 5000,
        //     reconnectionAttempts: Infinity
        //   });
        
        //console.log('infomation2',localStorage.getItem('infomation'))
    }

    onLogin(body: any) {
        return this.callApiService.callApiPost(Url.LOGIN, body);
    }

    setLocalStorage(token: string) {
        this.authToken = token;
        localStorage.setItem('token', this.authToken);
    }

    isHaveUserLogin() {
        return tokenNotExpired();
    }

    isAuthorized(allowedRoles: string[]): boolean {
        // check if the list of allowed roles is empty, if empty, authorize the user to access the page
        if (allowedRoles == null || allowedRoles.length === 0) {
            return true;
        }

        // let haveToken = localStorage.getItem('token');
        // // get token from local storage or state management
        // if (haveToken) {
        //     return true;
        // }
        // return false;
        //console.log('infomation',localStorage.getItem('infomation'))
        if (localStorage.getItem('infomation') != 'undefined') {
            let infomation = JSON.parse(localStorage.getItem('infomation'));
            if (infomation) {
                
              let listRoles = infomation.roleList;
              var checkExistsRolesAdmin = false;
              var checkExistsRolesWhlead = false;
              var checkExistsRolesMod = false;
              var checkExistsRolesCompany = false;
              var checkExistsRolesAgent = false;
              checkExistsRolesAdmin = listRoles[0].roleName.includes("ADMIN");
              checkExistsRolesWhlead = listRoles[0].roleName.includes("WHLEAD");
              checkExistsRolesMod = listRoles[0].roleName.includes("MOD");
              checkExistsRolesCompany = listRoles[0].roleName.includes("COMPANY");
              checkExistsRolesAgent = listRoles[0].roleName.includes("AGENT");
              //console.log(checkExistsRolesWhlead)
              if (checkExistsRolesAdmin) {
                return allowedRoles.includes('ADMIN');
              }
              if (checkExistsRolesWhlead) {
                  //console.log("check role",allowedRoles.includes('WHLEAD'))
                return allowedRoles.includes('WHLEAD');
              }
              if (checkExistsRolesMod) {
                return allowedRoles.includes('MOD');
              }
              if (checkExistsRolesCompany) {
              return allowedRoles.includes('COMPANY');
              }
              if (checkExistsRolesAgent) {
                return allowedRoles.includes('AGENT');
              }
            }
          }
    }
}