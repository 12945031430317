import { HttpHeaders } from '@angular/common/http';
import * as $ from 'jquery';
import { environment } from './../../environments/environment';
import * as JWT from 'jwt-decode';
export class NewsPost {
    title: string;
    link: string;
    creator: string;
    text: string;
}
export class AppSettings {
    //Local
    public static API_ENDPOINT = environment.API_ENDPOINT; //'http://localhost:6011';

        //chat root api
    public static ROOT_URL = window.location.hostname;
    public static protocol = window.location.protocol;
    public static API_ROOT = AppSettings.API_ENDPOINT;

        
    public static TOKEN = localStorage.getItem('token');
    public static httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': AppSettings.TOKEN,
        })
    };
    public static httpOptionForLogin = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        })
    }
    public static REPONSE_SERVER = {
        SUCCESS: 0,
        FAILED: 1,
    }
    public static validatorPhone(phone) {
        var re = new RegExp('^[0-9]+$');
        if (!phone) {
            return true;
        } else {
            if (!re.test(phone) || (phone.length < 9 || phone.length > 15)) {
                return false;
            } else {
                return true;
            }
        }
    }

    public static STATUS = {
        DELETE: 9,
        INACTIVE: 8,
        ACTIVE: 2,
        WAIT_ACTIVE: 1,
        REJECT: 3,
    };

    public static TOTAL_PAGES = 7;
    public static jobScope = {
        All: 'All',
        Cleaning: 'Cleaning',
        Technical: 'Technical',
        Security: 'Security',
        TN: 'RCN',
        ADMIN: 'ADMIN'
    }

    public static reformatDateString(s) {
        var b = s.split(/\D/);
        b[0] = (parseInt(b[0]) - 1).toString();
        if (parseInt(b[0]) < 10) {
            b[0] = ('0' + b[0]);
        }
        return b.reverse().join('-');
    }

    public static updateRead() {
        $(document).ready(function () {
            var title = $('title').text();
            let openCharactor = 0;
            for (let i = 0; i < title.length; i++) {
                if (title[i] == '(') {
                    openCharactor = i;
                }
            }
            let closeCharactor = 0;
            for (let i = 0; i < title.length; i++) {
                if (title[i] == ')') {
                    closeCharactor = i;
                }
            }
            let index;
            if (closeCharactor > 0) {
                index = title.substring(closeCharactor + 1, title.length);
            } else {
                index = title.substring(closeCharactor, title.length);
            }
            $('title').text(index);
        })
    }
    public static menuHeader = {
        PROFILE: 'Thông tin',
        LOGOUT: 'Đăng xuất',
    }
    public static locDau(str) {
        if (!str) {
            return str
        }
        str = str.toLowerCase();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ|à|ã/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ|ẹ|ẽ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ|ĩ|ì/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ộ|ớ|ợ|ở|ỡ|ọ|ò|õ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ|ụ|ù/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/̉/g, "");
        str = str.replace(/́/g, "");
        str = str.replace(/!|@|\$|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\'| |\"|\&|\#|\[|\]|~/g, "-");
        str = str.replace(/-+-/g, "-"); //thay thế 2- thành 1-
        str = str.replace(/^\-+|\-+$/g, "");//cắt bỏ ký tự - ở đầu và cuối chuỗi
        str = str.replace(/-/g, " "); //thay thế - thành space
        return str;
    };

    public static getUserID() {
        let userID = '';
        let token = localStorage.getItem('token');
        if (token && typeof token != 'undefined') {
            let decoded = JWT(token);
            userID = decoded.userID;
        }
        return userID;
    }

    public static userStatus = {
        
    }

}