import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/services/auth.guard';
import { IsLoggedInGuard } from './auth/services/is-logged-in.guard';

const routes: Routes = [
  {
    path: 'pages', loadChildren: 'app/pages/pages.module#PagesModule',
    canActivate : [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: './auth/auth.module#NgxAuthModule',
    canActivate : [IsLoggedInGuard],
  },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: false,
};

export const routing = RouterModule.forRoot(routes, config);
