import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// import * as io from 'socket.io-client';
// import { Const } from './../../configs/constant';
let switchLanguage: any = null;
@Injectable()

export class CommonSerive {
    switchLanguage = new BehaviorSubject(switchLanguage);
    switchLanguage$ = this.switchLanguage.asObservable();
    // public socket = io('http://127.0.0.1:6011', {
    //     transports: ['polling'],
    //     reconnection: true,
    //     reconnectionDelay: 1000,
    //     reconnectionDelayMax: 5000,
    //     reconnectionAttempts: Infinity
    // });
}