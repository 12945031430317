import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
@Injectable()
export class IsLoggedInGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _router: Router,
  ) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this._authService.isHaveUserLogin()) {
      if (localStorage.getItem('infomation') != 'undefined') {
        let infomation = JSON.parse(localStorage.getItem('infomation'));
        let listRoles = infomation.roleList;
        var checkExistsRolesCompany = listRoles[0].roleName.includes("COMPANY");
        var checkExistsRolesAgent = listRoles[0].roleName.includes("AGENT");
        if (checkExistsRolesAgent) {
          this._router.navigate(['/pages/danh-sach-xe/list-xe']);
        }
        if (checkExistsRolesCompany) {
          this._router.navigate(['/pages/danh-sach-xe/list-xe']);
        }
      }
    }
    return true;
  }
}