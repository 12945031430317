import { Injectable } from '@angular/core';
import { CallApiService } from '../../../configs/call-api.service';
import { Url } from '../../../configs/url';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(
        private callApiService: CallApiService
    ) { }

    getNotification(body) {
        body.language = 'vi';
        return this.callApiService.callApiPost(Url.SEARCH_NOTIFICATION, body)
    }

    readAll(body) {
        body.language = 'vi';
        return this.callApiService.callApiPost(Url.READ_ALL_NOTIFICATION, body)
    }

    deleteByID(body) {
        body.language = 'vi';
        return this.callApiService.callApiPost(Url.DELETE_NOTIFICATION_BY_ID, body)
    }

    readOne(body) {
        body.language = 'vi';
        return this.callApiService.callApiPost(Url.READ_ONE_NOTIFICATION, body)
    }
}
