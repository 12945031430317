import Swal from 'sweetalert2';

export function error(msg) {
    return Swal.fire({
        type: 'error',
        title: 'Có lỗi xảy ra',
        text: msg,
    });
}

export function success(msg) {
    Swal.fire({
        type: 'success',
        title: 'Thông báo',
        text: msg,
    });
}

export function htmlAlert(content) {
    Swal.fire({
        title: '<strong>Thông báo</strong>',
        type: 'info',
        html:
            `<p style = "margin-top : 10px;margin-bottom : 3px">Các file</p>` +
            `${content}`,
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText:
            'OK',
        confirmButtonAriaLabel: 'Thumbs up, great!',
    });
}

export function confirmDialogAlert(title) {
    return Swal.fire({
        title: 'Cảnh báo',
        text: title,
        type: 'warning',
        showCloseButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Đồng ý',
        reverseButtons: true,
    });
}

export function confirmDialog(title, custom = { confirm: 'Xoá', cancel: 'Hủy' }) {
    return Swal.fire({
        title: 'Cảnh báo',
        text: title,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3366ff',
        confirmButtonText: custom.confirm,
        cancelButtonText: custom.cancel,
        reverseButtons: true,
    });
}

export function confirmGiaoViec(title) {
    return Swal.fire({
        title: 'Cảnh báo',
        text: title,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3366ff',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
        reverseButtons: true,
    });
}
export function confirmVehicle(title) {
    return Swal.fire({
        title: 'Cảnh báo',
        text: title,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3366ff',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
        reverseButtons: true,
    });
}

