// let internals : any = {};

// internals.JWT = {
//     JWT_SECRET: "DL5j4pvCcbGMxIxacGEXPJlg00zfe8Z/5554f4i+Q4NTN4V3KyaQiNsqcwy+v4D0r3qV+Qx/MSMqAsfodJBn4eVxXvdSNofcBuCQeiNNekhOJuZv0OpfRCKSlHLKpKlYznwVv/Cyy5A+Qsu5OLzVAJoURovd4KundnTUphhwiCUUGFK5/aqIM6vQbZ5369dSCby+EB94jhyVCF13wHqQXTRundu7U/0P1k6Xhz8GJuklHj/F7J1nDnoSo6pOK+r64CCessWTH410YIPeHwQL3woGB4//0VupH6r7zkDvYfdA34sOTfeJpCqxSLaZuNu7B3Nm5Lt/5cMa8kJykKOkBg=="
// };

// internals.ROLE = {
//     ROOT_ROLE: "ROOT",
//     ADMIN_ROLE: "ADMIN",
//     SALE_ROLE: "SALE",
//     AN_NINH_ROLE: "AN",
//     TIEP_NHAN_ROLE: 'RCN',
//     THU_NGAN_ROLE: 'CSR',
//     KY_THUAT_ROLE: "KT",
//     VE_SINH_ROLE: "VS",
//     BAT_KY_ROLE: "All"
// };

// internals.MAPPING_ROLE = {
//     "Root": "Hệ thống",
//     "Admin": "Quản trị",
//     "Security": "An ninh",
//     "Receptionist": "Lễ tân",
//     "Technical": "Kỹ thuật",
//     "Cleaning": "Vệ sinh",
//     "All": "Bất kỳ"
// };

// internals.CHAT_MESSAGE_TYPE = {
//     TEXT: 'Text',
//     IMAGE: 'Image',
//     LOCATION: 'Location'
// };

// internals.PERMISSION = {
//     PERMISSION_DENIED: 'Quyền người dùng không hợp lệ.',
// };

// internals.PASSWORD = {
//     DEFAULT: '123456',
// };

// internals.STATUS_SYSTEM = {
//     INACTIVE: 'I',
//     ACTIVE: 'A',
//     PENDING: 'P',
// };

// internals.STATUS_MACHINE = {
//     WORKING: 'W',
//     RETIRED: 'R',
//     PROBATION: 'P',
//     FRESHER: 'F'
// }

// internals.GENDER = {
//     MALE: 'M',
//     FEMALE: 'F',
//     UNKNOWN: 'U'
// }

// internals.TYPE_LANGUAGE = {
//     VIET_NAM: ['vi-VN', 'vi', 'VI', 'VI-VN', 'VI-vn', 'vi-US'],
//     JAPAN: ['ja', 'JA', 'JA-JP', 'ja-JP'],
//     ENGLISH: ['en-AU', 'en-BZ', 'en-CA', 'en-IE', 'en-JM', 'en-NZ', 'en-PH', 'en-ZA', 'en-TT', 'en-US', 'en-UK', 'en-XA', 'en-GB', 'en-ZW', 'en']
// };

// internals.PROCESS_STATUS = {
//     OK: 'OK',
//     NOTOK: 'NOTOK',
//     WAIT: 'WAIT'
// }

export class Const {

    public static NOTIFICATION_CATEGORY = {
        DETAIL_CUSTOMER_REQUEST: 1,
    };

    public static NOTIFICATION_TABLE = {
        CUSTOMER_REQUEST: 'CustomerRequest',
        ASSIGNMENT:'Assignment',
    };

    public static WORKFLOW = {
        BAO_GIA: 'BAO_GIA',
    };

    public static PROCESS_STATUS = {
        OK: 'OK',
        NOTOK: 'NOTOK',
        WAIT: 'WAIT',
    };

    public static PROCESS_ACTION = {
        OK: 'ok',
        NOTOK: 'notok',
    };

    public static SYSTEM_PARAM = {
        GIA_CO_SO: 'GIA_CO_SO',
        HE_SO_GIA: 'HE_SO_GIA',
        GIA_NHIET_LUYEN_1: 'GIA_NHIET_LUYEN_1',
        GIA_NHIET_LUYEN_2: 'GIA_NHIET_LUYEN_2',
        GIA_NHIET_LUYEN_3: 'GIA_NHIET_LUYEN_3',
        GIA_XUNG_DIEN_1: 'GIA_XUNG_DIEN_1',
        GIA_XUNG_DIEN_2: 'GIA_XUNG_DIEN_2',
        GIA_CAT_DAY_1: 'GIA_CAT_DAY_1',
        GIA_CAT_DAY_2: 'GIA_CAT_DAY_2',
        GIA_CAT_DAY_3: 'GIA_CAT_DAY_3',
        GIA_CAT_DAY_4: 'GIA_CAT_DAY_4',
        GIA_MA_A_1: 'GIA_MA_A_1',
        GIA_MA_A_2: 'GIA_MA_A_2',
        GIA_MA_AM_1: 'GIA_MA_AM_1',
        GIA_MA_AM_2: 'GIA_MA_AM_2',
        GIA_MA_AC_1: 'GIA_MA_AC_1',
        GIA_MA_AC_2: 'GIA_MA_AC_2',
        GIA_MA_C_1: 'GIA_MA_C_1',
        GIA_MA_C_2: 'GIA_MA_C_2',
        GIA_MA_K_1: 'GIA_MA_K_1',
        GIA_MA_K_2: 'GIA_MA_K_2',
        GIA_MA_NI_1: 'GIA_MA_NI_1',
        GIA_MA_NI_2: 'GIA_MA_NI_2',
        GIA_MA_NC_1: 'GIA_MA_NC_1',
        GIA_MA_NC_2: 'GIA_MA_NC_2',
        GIA_MA_ND_1: 'GIA_MA_ND_1',
        GIA_MA_ND_2: 'GIA_MA_ND_2',
    };

    public static STATUS_LABEL = {
        '': '',
        'new': 'Mới',
        'processing': 'Đang xử lý',
        'finished': 'Kết thúc',
        'rejected': 'Hủy',
    };

    public static numberComma(number) {
        const rawEventValue = number.toString();
        if (rawEventValue.substr(0, 1) === '-') {
            const a = '-' + rawEventValue.substr(1).replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            // this.quantityNumber = event.target.value;
            return a;
        } else {
            const a = rawEventValue.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            // this.quantityNumber = event.target.value;
            return a;
        }
    }

    public static checkDate(data) {
        if (!data) {
            return;
        }
        const bits = data.split('/');
        const d = new Date(bits[2], bits[1] - 1, bits[0]);
        return d && (d.getMonth() + 1) === bits[1];
    }

    public static converTimetamp(date: string) {
        date = date ? date : '';
        const dates1 = date.split('/');
        const newDate = dates1[1] + '/' + dates1[0] + '/' + dates1[2];
        return new Date(newDate).getTime();
    }
}
