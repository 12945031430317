import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { tap,catchError, map } from 'rxjs/operators';
import { throwError,timer, Observable, of } from 'rxjs';
import { environment } from './../../environments/environment';
@Injectable()

export class CallApiService {
    public token = localStorage.getItem('token');
    constructor(
        private http: HttpClient,
    ) { }
    callApiPost(url: string, body?: any) {
        return this.http.post(`${environment.API_ENDPOINT}/${url}`, body).pipe(
            map((data: any) => data),
        );
    }
    callPostApiByToken(urlSend: string, payload?: any): Observable<any> {
        console.log('==token======',this.token);
        const url = `${environment.API_ENDPOINT}/${urlSend}`;
        return this.http.post(url, payload, {
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.token.toString()
            }
        }).pipe(
            tap(() => console.log()),
            map((res: any) => res),
            catchError(err => of(err))
        );
    }
}
