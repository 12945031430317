export enum Url {
    // Auth
    LOGIN = 'api/user/login',
    CREATE_USER = 'api/user/create',
    UPDATE_USER = 'api/user/update',
    DELETE_USER = 'api/user/delete',
    RESET_PASSWORD = 'api/user/resetPassword',

    // Notification
    SEARCH_NOTIFICATION = 'api/notification/search',
    READ_ALL_NOTIFICATION = 'api/notification/readAll',
    DELETE_NOTIFICATION_BY_ID = 'api/notification/deleteByID',
    READ_ONE_NOTIFICATION = 'api/notification/readOne',

    // vehicle
    SEARCH_VEHICLE = 'api/vehicle/search',
    UPDATE_VEHICLE = 'api/vehicle/updateByID',
    GET_VEHICLE_BY_ID = 'api/vehicle/getByID',
    SEARCH_VEHICLE_FOR_WEB = 'api/vehicle/searchForWeb',
    SEARCH_VEHICLE_FOR_EXPORT_EXCEL = 'api/vehicle/searchForExportExcel',

    // security checkt
    SEARCH_SCTCHECK= 'api/securityCheck/search',
    CREATE_VEHICLE = 'api/securityCheck/create',
    
    // xe-khong-nguon-goc
    SEARCH_XE_KHONG_NGUON_GOC = 'api/vehicleTemp/search',
    UPDATE_XE_KHONG_NGUON_GOC = 'api/vehicleTemp/updateByID',
    DELETE_XE_KHONG_NGUON_GOC = 'api/vehicleTemp/delete',

    // giao-viec-group
    GET_GiAO_VIEC_GROUP = 'api/assignmentGroup/search',
    CREATE_GiAO_VIEC_GROUP = 'api/assignmentGroup/createMulti',
    GET_GIAO_VIEC_GROUP_BY_ID = 'api/assignmentGroup/getByID',
    UPDATE_BY_ID_GiAO_VIEC_GROUP = 'api/assignmentGroup/updateByID',
    UPDATE_ASSIGNMENT_GROUP_MULTI = 'api/assignmentGroup/updateMulti',
    DELETE_GiAO_VIEC_GROUP = 'api/assignmentGroup/delete',
    GET_COUNT_ALL_ASSIGNMENTGROUP = 'api/assignmentGroup/count',

    // giao-viec
    GET_GiAO_VIEC = 'api/assignment/search',
    CREATE_GiAO_VIEC = 'api/assignment/create',
    UPDATE_BY_ID_GiAO_VIEC = 'api/assignment/updateByID',
    DELETE_GiAO_VIEC = 'api/assignment/delete',
    GET_ALL_DMVEHICLE = 'api/dm-vehicle/getAll',
    GET_ALL_TASK = 'api/task/search',
    CREATE_GiAO_VIEC_DETAIL = 'api/assignmentDetail/create',
    GET_GIAOVIEC_BY_ID = 'api/assignment/getByID',
    UPDATE_BY_ID_GiAO_VIEC_DETAIL = 'api/assignmentDetail/updateByID',
    UPDATE_ASSIGNMENT_MULTI = 'api/assignment/updateMulti',
    UPDATE_ASSIGNMENT_MULTI_FOR_GIAO_VIEC = 'api/assignment/updateMultiForGiaoViec',
    GET_COUNT_ALL_ASSIGNMENT = 'api/assignment/countByGroup',

    // DELIVERY REQUEST
    SEARCH_DELIVERY_REQUEST = 'api/deliveryRequest/search',
    CREATE_DELIVERY_REQUEST = 'api/deliveryRequest/create',
    UPDATE_DELIVERY_REQUEST = 'api/deliveryRequest/updateByID',
    UPDATE_DELIVERY_REQUEST_DATE = 'api/deliveryVehicle/updateDeliveryDate',
    DELETE_DELIVERY_REQUEST = 'api/deliveryRequest/delete',
    GET_BY_ID_DELIVERY_REQUEST = 'api/deliveryRequest/getByID',
    GET_VEHICLE_BY_DOTONUMBER = 'api/deliveryRequest/getVehicleByDoToNumbers',
    CREATE_MULTI_DELIVERY_REQUEST = 'api/deliveryRequest/createMulti',


    // Vehicle
    CREATE_MULTI_VEHICLE = 'api/vehicle/createMulti',
    UPDATE_MULTI_VEHICLE = 'api/vehicle/updateMulti',
    DELETE_VEHICLE = 'api/vehicle/delete',
    GET_VEHICLE_BY_CHASSISSNUMBER = 'api/vehicle/getVehicleByChassissNumber',
    GET_ALL_VEHICLE = 'api/vehicle/getAllVehicle',

    // delivery vehicle
    CREATE_MULTI_DELIVERY_VEHICLE = 'api/deliveryVehicle/createMulti',
    UPDATE_MULTI_DELIVERY_VEHICLE = 'api/deliveryVehicle/updateMulti',
    GET_DELIVERY_VEHICLE = 'api/deliveryVehicle/getVehicleByDeliveryRequestID',
    DELETE_DELIVERY_VEHICLE = 'api/deliveryVehicle/delete',
    UPDATE_MULTI_IMPORT_FILE = 'api/deliveryVehicle/updateMultiImportFile',

    // employee
    SEARCH_FULLNAME_EMPLOYEE = 'api/employee/searchFullName',
    SEARCH_EMPLOYEE = 'api/employee/search',
    CREATE_EMPLOYEE = 'api/employee/create',
    UPDATE_EMPLOYEE = 'api/employee/update',
    DELETE_EMPLOYEE = 'api/employee/delete',
    // role
    SEARCH_ROLE = 'api/role/search',

    // dm-brand
    SEARCH_DM_BRAND = 'api/dm-brand/search',
    CREATE_DM_BRAND = 'api/dm-brand/create',
    UPDATE_DM_BRAND = 'api/dm-brand/updateByID',
    DELETE_DM_BRAND = 'api/dm-brand/delete',
    GET_ALL_BRANDS = 'api/dm-brand/getAllBrand',

    // dm-vehicle-name
    SEARCH_DM_VEHICLE_NAME = 'api/dm-vehicle-name/search',
    CREATE_DM_VEHICLE_NAME = 'api/dm-vehicle-name/create',
    UPDATE_DM_VEHICLE_NAME = 'api/dm-vehicle-name/updateByID',
    DELETE_DM_VEHICLE_NAME = 'api/dm-vehicle-name/delete',

    // dm-vehicle
    SEARCH_DM_VEHICLE = 'api/dm-vehicle/search',
    CREATE_DM_VEHICLE = 'api/dm-vehicle/create',
    UPDATE_DM_VEHICLE = 'api/dm-vehicle/updateByID',
    DELETE_DM_VEHICLE = 'api/dm-vehicle/delete',
    GET_LIST_PDI_TYPE = 'api/dm-vehicle/getListPdiTypeByBrandID',

    // dm-extra
    SEARCH_DM_EXTRA = 'api/extra/search',
    SEARCH_ALL_DM_EXTRA = 'api/extra/searchAll',
    CREATE_DM_EXTRA = 'api/extra/create',
    UPDATE_DM_EXTRA = 'api/extra/updateByID',
    DELETE_DM_EXTRA = 'api/extra/delete',

    // dm-vehicle-extra
    CREATE_DM_VEHICLE_EXTRA = 'api/extra/createVehicleExtra',
    UPDATE_DM_VEHICLE_EXTRA = 'api/extra/updateVehicleExtra',
    DELETE_DM_VEHICLE_EXTRA = 'api/extra/deleteVehicleExtra',



    // DM_material
    GET_ALL_DM_MATERIAL = 'api/material/search',
    CREATE_DM_MATERIAL = 'api/material/create',
    UPDATE_DM_MATERIAL = 'api/material/updateByID',
    DELETE_DM_MATERIAL = 'api/material/delete',
    MAKE_MATERIAL_STATISTIC = 'api/material/makeStatistic',
    SEARCH_MATERIAL_IO = 'api/materialIO/search',
    CREATE_MATERIAL_IO = 'api/materialIO/create',
    CREATE_MATERIAL_IO_FOR_ASSIGNMENT_UPDATE = 'api/materialIO/createForAssignmentUpdate',
    UPDATE_MATERIAL_IO_DETAIL = 'api/materialIODetail/updateByID',
    GET_MATERIALIO_BY_ASSIGNMENTID = 'api/materialIO/searchByAssignmentID',

    // AssignmentMaterial
    CREATE_ASSIGNMENT_MATERIAL = 'api/assignmentMaterial/create',
    UPDATE_ASSIGNMENT_MATERIAL = 'api/assignmentMaterial/updateMulti',
    SEARCH_ASSIGNMENT_MATERIAL = 'api/assignmentMaterial/search',

    // dm-route
    SEARCH_DM_ROUTE = 'api/route/search',
    SEARCH_LOCATION = 'api/route/searchLocation',
    CREATE_DM_ROUTE = 'api/route/create',
    UPDATE_DM_ROUTE = 'api/route/updateByID',
    DELETE_DM_ROUTE = 'api/route/delete',

    // DM_vehicle
    GET_ALL_DMVEHICLE_GIAOVIEC = 'api/dm-vehicle/getAllForGiaoviec',
    SEARCH_EXPORT_VEHICLE = 'api/dm-vehicle/searchExportVehicle',

    // dm-Place
    SEARCH_DM_PLACE = 'api/place/search',
    GET_ALL_COMPANY = 'api/place/getAllCompany',
    CREATE_DM_PLACE = 'api/place/create',
    DELETE_DM_PLACE = 'api/place/delete',
    UPDATE_DM_PLACE = 'api/place/updateByID',

    // DM_COLOR
    SEARCH_DM_COLOR = 'api/color/search',
    CREATE_DM_COLOR = 'api/color/create',
    DELETE_DM_COLOR = 'api/color/delete',
    UPDATE_DM_COLOR = 'api/color/updateByID',

    // dm-transporter
    SEARCH_DM_TRANSPORTER = 'api/transporter/search',
    FULL_TEXT_SEARCH_TRANSPORTER = 'api/transporter/fullTextSearchTransporter',
    CREATE_DM_TRANSPORTER = 'api/transporter/create',
    DELETE_DM_TRANSPORTER = 'api/transporter/delete',
    UPDATE_DM_TRANSPORTER = 'api/transporter/updateByID',

    // dm-task
    CREATE_DM_TASK = 'api/Task/create',
    DELETE_DM_TASK = 'api/Task/delete',
    UPDATE_DM_TASK = 'api/Task/updateByID',
    CREATE_DM_SUBTASK = 'api/subTask/create',
    UPDATE_DM_SUBTASK = 'api/subTask/updateByID',
    DELETE_DM_SUBTASK = 'api/subTask/delete',

    // dm-handover-checklist
    SEARCH_HANDOVER_CHECKLIST = 'api/handoverCheckList/search',
    CREATE_HANDOVER_CHECKLIST = 'api/handoverCheckList/create',
    DELETE_HANDOVER_CHECKLIST = 'api/handoverCheckList/delete',
    UPDATE_HANDOVER_CHECKLIST = 'api/handoverCheckList/updateByID',

    // dm-group
    // GET_GROUP_BY_TASKID = 'api/group/searchByTaskID',
    SEARCH_DM_GROUP = 'api/group/search',
    CREATE_DM_GROUP = 'api/group/create',
    UPDATE_DM_GROUP = 'api/group/updateByID',
    DELETE_DM_GROUP = 'api/group/delete',
    SEARCH_DM_EMPLOYEE = 'api/employeeGroup/searchByGroupID',
    CREATE_DM_EMPLOYEE = 'api/employeeGroup/create',
    DELETE_DM_EMPLOYEE = 'api/employeeGroup/delete',
    UPDATE_DM_EMPLOYEE = 'api/employeeGroup/updateByID',
    // system-param
    SEARCH_PARAM = 'api/system-param/search',
    UPDATE_PARAM = 'api/system-param/updateByID',

    // maintainAndExportWork
    CREATE_MULTI_MAINTAIN_AND_EXPORT_WORK = 'api/maintainAndExportWork/createMulti',
    SEARCH_MAINTAIN_AND_EXPORT = 'api/maintainAndExportWork/search',
    UPDATE_MAINTAIN_AND_EXPORT = 'api/maintainAndExportWork/updateByID',
    UPDATE_MULTI_MAINTAIN_AND_EXPORT = 'api/maintainAndExportWork/updateMulti',
    DELETE_MAINTAIN_AND_EXPORT = 'api/maintainAndExportWork/delete',
    AUTO_UPDATE_MAINTAIN_AND_EXPORT_LIST = 'api/maintainAndExportWork/autoUpdateList',

    // Geo-History
    SEARCH_GEO_HISTORY = 'api/geoHistory/search',

    // TaskGroup
    SEARCH_GROUP_TASK_BY_CODE = 'api/taskGroup/searchByCode',
    CREATE_TASK = 'api/taskGroup/create',
    UPDATE_TASK = 'api/taskGroup/updateByID',
    DELETE_TASK = 'api/taskGroup/delete',

    // ErrorLog
    SEARCH_ERROR_LOG = 'api/errorLog/search',

    //PDI
    SEARCH_PDI = 'api/pdi/search',
    CREATE_PDI = 'api/pdi/create',
    UPDATE_PDI = 'api/pdi/updateByID',
    DELETE_PDI = 'api/pdi/delete',

    //Sub PDI
    SEARCH_SUB_PDI = 'api/subPdi/search',
    CREATE_SUB_PDI = 'api/subPdi/create',
    DELETE_SUB_PDI = 'api/subPdi/delete',
    UPDATE_SUB_PDI = 'api/subPdi/updateByID',

    //SECURITY_CHECK
    CREATE_SECURITY_CHECK ='api/securityCheck/create',

    // ParkingLocation
    SEARCH_PARKING_LOCATION = 'api/parkingLocation/search',
    CREATE_UPDATE_LOCATION = 'api/parkingLocation/createMulti',
    DELETE_PARKING_LOCATION_BY_ID = 'api/parkingLocation/delete',
    GET_ALL_PARKING_LINE = 'api/parkingLocation/getAllLine',
    GET_ALL_PARKING_ZONE = 'api/parkingLocation/getAllZone',
    DELETE_PARKING_LOCATION_MULTI = 'api/parkingLocation/deleteMulti',

    //ConfigReport
    SEARCH_REPORT_BY_PLACEID = 'api/reportConfig/searchByPlaceID',
    CREATE_REPORT = 'api/reportConfig/create',
    UPDATE_REPORT = 'api/reportConfig/updateByID',
    DELETE_REPORT = 'api/reportConfig/delete',
    GET_FOR_EXPORT_EXCEL = 'api/reportConfig/getForExportExcel',

    // fuelHistory
    SEARCH_FUEL_HISTORY = 'api/fuelHistory/search',

    // Maintenance Vehicle
    SEARCH_MAINTENANCE_VEHICLE = 'api/maintenanceVehicleDetail/searchAllMaintenanceVehicle',
    SEARCH_MAINTENANCE_VEHICLE_DETAIL_BY_ASSIGNMENTID = 'api/maintenance/searchForMobile',

    // PDIVehicle
    SEARCH_PDI_VEHICLE = 'api/pdiVehicle/searchAllVehiclePdiTestDone',
    SEARCH_PDI_VEHICLE_BY_VEHICLEID = 'api/pdiVehicle/searchByVehicleID',

    // Printlog
    CREATE_PRINT_LOG = 'api/print-log/create',

    //Maintenance
    SEARCH_MAINTENANCE = 'api/maintenance/search',
    CREATE_MAINTENANCE = 'api/maintenance/create',
    UPDATE_MAINTENANCE = 'api/maintenance/updateByID',
    DELETE_MAINTENANCE = 'api/maintenance/delete',

}
