import { Component, Input, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import * as $ from 'jquery';
import { Router, NavigationExtras } from '@angular/router';
import * as JWT from 'jwt-decode';
import uuid from 'uuid';
import { statusServer } from '../../../configs/serverConstant';
import { error, success } from '../../../shared/message-alert/message-alert';
import { Const } from '../../../configs/constant';
import { NotificationService } from '../../../pages/notification/services/notification.service';
import {AuthService} from '../../../auth/services/auth.service'
import { CommonSerive } from '../../../shared/services/common.service';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
    host: { "(document:click)": "onClickBody($event)" }
})

export class HeaderComponent implements OnInit {

    @Input() position = 'normal';

    public decoded: any = {};
    public user: any = {};
    public userMenu = [
        { title: 'Trang cá nhân' },
        { title: 'Đăng xuất' }
    ];
    public isShowNotifications: boolean = false;
    public notifyList: any = [];
    public countNotify: number = 0;

    constructor(
        private sidebarService: NbSidebarService,
        private menuService: NbMenuService,
        private router: Router,
        private serviceNotify: NotificationService,
        private authService: AuthService,
        private commonSerivice: CommonSerive,
    ) { }

    ngOnInit() {
        this.authService.socket.on("alert_new_event", (msg: any) => {
            console.log('socket on alert_new_event', msg)
            
            this.countNotify = this.countNotify + 1;

            msg.img = msg.img == undefined ? '../../../assets/images/avatar-default.png' : msg.img;
      
            if (this.notifyList.length == 0)
              this.notifyList.push(msg);
            else {
              this.notifyList = [msg, ...this.notifyList]
            }
            if(this.notifyList.length >= 8){
                this.notifyList.splice(this.notifyList.length-1,1);
            }
          });

        this.menuService.onItemClick().subscribe(event => {
            this.onItemSelection(event.item.title);
        });
        this.getNotify();
        this.decode();
        this.switchLanguage();
    }

    getNotify() {
        this.countNotify = 0;
        this.serviceNotify.getNotification({ limit: 100 }).subscribe((res: any) => {
            if (res.status != statusServer.success) {
                error(res.msg);
                return;
            }
            this.notifyList = [];
            for (let i = 0; i < res.data.length; ++i) {
                let item = res.data[i];
                if (item.isRead === 0)
                    this.countNotify++;
                if(i<8) {
                    this.notifyList.push(item);
                }
            }

            /*for(let i = 0; i < 8; i++ ){
                this.notifyList.push(res.data[i]);
            }*/
            
        })
    }

    decode() {
        this.decoded = JWT(localStorage.getItem('token'));
        this.user = { name: this.decoded.fullName, picture: this.decoded.avatar ? this.decoded.avatar : 'https://i.imgur.com/ik5jqGX.png' };
    }

    onItemSelection(event) {
        if (event === 'Trang cá nhân') {
            this.viewProfile();
        }
        if (event === 'Đăng xuất') {
            localStorage.clear();
            this.router.navigate(["/auth/login"]);
        }
    }

    viewProfile() {
        let id = uuid.v4() + this.decoded.employeeID + uuid.v4();
        let queryParams: NavigationExtras = {
            queryParams: {
                "id": id,
            }
        }
        this.router.navigate(['/pages/employee/detail-employee'], queryParams);
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        return false;
    }

    readAll() {
        this.serviceNotify.readAll({}).subscribe((res: any) => {
            if (res.status != statusServer.success) {
                error(res.msg);
                return;
            }
            success(res.msg);
            this.getNotify();
        })
    }

    readOne(item) {
        this.serviceNotify.readOne({ id: item.id }).subscribe((res: any) => {
            if (res.status != statusServer.success) {
                error(res.msg);
                return;
            }
            this.getNotify();
            this.isShowNotifications = false;
            if (item.table === Const.NOTIFICATION_TABLE.CUSTOMER_REQUEST && item.category === Const.NOTIFICATION_CATEGORY.DETAIL_CUSTOMER_REQUEST) {
                let id = uuid.v4() + item.recordID + uuid.v4();
                let queryParams: NavigationExtras = {
                    queryParams: {
                        "id": id,
                    }
                }
                this.router.navigate(['/pages/manage-request/detail-request'], queryParams)
            }else if (item.table === Const.NOTIFICATION_TABLE.ASSIGNMENT) {
                let id = uuid.v4() + item.assignmentGroupID + uuid.v4();
                let queryParams: NavigationExtras = {
                    queryParams: {
                        "id": id,
                    }
                }
                this.router.navigate(['/pages/giao-viec/tao-cong-viec'], queryParams)
            }
        })
    }

    selectOptionValue(event,value){
        // this.commonSerivice.switchLanguage.next(value);
        // localStorage.setItem('language', value);
        // this.router.navigate([this.router.url]);
    }

    switchLanguage() {
        $(document).ready(function () {
            let isDropdownOpen = false;
            const buttonSelectLanguage = $('.language ul.button-select-language');
            const dropdownLanguage = $('.language ul.dropdown-language');
            buttonSelectLanguage.click((event) => {
                const $this = $(event.currentTarget);
                $this.toggleClass('highlighted');
                dropdownLanguage
                    .slideDown()
                    .css('display', 'flex');
                isDropdownOpen = true;
            });
            dropdownLanguage
                .find('li').click((event) => {
                    const $this = $(event.currentTarget);
                    dropdownLanguage
                        .hide()
                        .find('li').removeClass('selected');
                    $this.addClass('selected');
                    const chosenFlag = $this
                        .find('img').attr('src');
                    buttonSelectLanguage
                        .removeClass('highlighted')
                        .find('img').attr('src', chosenFlag);
                    isDropdownOpen = false;
                });
            // INFO: if click in document, hide isDropdownOpen.
            $(document).mouseup((e) => {
                if (isDropdownOpen) {
                    // INFO: check that you don't click to the dropdown or any of its children
                    if (!dropdownLanguage.is(e.target) && dropdownLanguage.has(e.target).length === 0) {
                        dropdownLanguage.hide();
                        isDropdownOpen = false;
                        buttonSelectLanguage
                            .removeClass('highlighted')
                            .show();
                    }
                }
            });
        })
    }

    showNotifications() {
        this.getNotify();
        this.isShowNotifications = !this.isShowNotifications;
    }
    
    seeAllNotification() {
        this.isShowNotifications = false; let queryParams: NavigationExtras = {
            queryParams: {
                "page": 1,
                "size": 20
            }
        }
        this.router.navigate(['/pages/notification/list-notification'], queryParams);
    }

    onClickBody(event) {
        let $this = $(event.target);
        let $parent = $this.closest("#notifications");
        if (!($parent && $parent.length > 0)) {
            this.isShowNotifications = false;
        }
    }
}